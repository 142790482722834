







import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import WhitelabelSettings from '@/components/accountant/WhitelabelSettings.vue';

@Component({
  components: {
    WhitelabelSettings,
  },
})
export default class AccountantSettingsPage extends Vue {}
