












































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { THEME_LIGHT, ThemeColors } from '@/lib/themes';
import ThemeColorPicker from '@/components/settings/ThemeColorPicker.vue';
import { AccountantService, ImageService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

const sTheme = namespace('theme');

@Component({
  components: {
    ThemeColorPicker,
  },
})
export default class AccountantSettingsPage extends Vue {
  @sTheme.State('logo') logo: string;
  @sTheme.Getter('colors') colors: ThemeColors;
  @sTheme.Action('setColorScheme') setColors: (ThemeColors) => void;
  @sTheme.Action('set') setTheme: (object) => void;

  loading: boolean = true;
  theme: any;

  async mounted(): Promise<void> {
    try {
      this.theme = await AccountantService.getTheme();
      this.loading = false;
    } catch (e) {
      if ((e as any).response.status === 404) {
        return;
      }
      this.$toaster.error('Error loading whitelabel', unwrapError(e));
    }
  }

  async destroyed(): Promise<void> {
    this.reset();
  }

  onChange(field: string, color: string): void {
    this.setColors({ [field]: color });
  }

  reset(): void {
    if (!this.theme) return;
    this.setTheme(this.theme);
  }

  resetDefault(): void {
    this.setTheme({ logo: null, colors: THEME_LIGHT });
  }

  async saveTheme(): Promise<void> {
    try {
      await AccountantService.updateTheme({
        logo: this.logo,
        colors: this.colors,
      });
      this.theme = { logo: this.logo, colors: this.colors };
      this.$toaster.success('Theme saved');
    } catch (e) {
      this.$toaster.error(this.$tc('messages.error.unknown'), unwrapError(e));
    }
  }

  async onLogoChange(event: Event): Promise<void> {
    const target = event.target as HTMLInputElement;
    if (!target || !target.files || target.files.length !== 1) return;
    const file = target.files[0];
    const imageUrl = await ImageService.uploadWhitelabelLogo(file);
    this.setTheme({ logo: imageUrl });
  }

  get logoName(): string {
    if (!this.logo) return '';
    return this.logo.split('/').pop() || '';
  }
}
