















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Chrome } from 'vue-color';
import Popover from '@/components/Popover.vue';

@Component({
  components: {
    'color-picker': Chrome as any,
    Popover,
  },
})
export default class ThemeColorPicker extends Vue {
  @Prop({ required: true })
  color: string;

  @Prop({ required: true })
  name: string;

  @Prop({ default: false, type: Boolean })
  withAlpha: boolean;

  onInput(event: Record<string, any>): void {
    this.$emit('input', this.withAlpha ? event.hex8 : event.hex);
  }
}
